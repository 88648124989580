import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input, Image } from 'semantic-ui-react';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { defineMessages, injectIntl, useIntl } from 'react-intl'; // Import injectIntl

import hamburguer from "../../../../../public/assets/hamburguer.svg";
import searchclose from "../../../../../public/assets/search-close.svg";
import searchicon from "../../../../../public/assets/search-icon.svg";

import { Container, Segment } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';
import { UniversalLink } from '@plone/volto/components';
import { toBackendLang } from '@plone/volto/helpers';
import LogoImage from '@plone/volto/components/theme/Logo/Logo.svg';

const messages = defineMessages({
  site: {
    id: 'Site',
    defaultMessage: 'Site',
  },
  plonesite: {
    id: 'Plone Site',
    defaultMessage: 'Plone Site',
  },
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
});

const SearchWidget = ({ intl, showSearch, setShowSearch }) => {
  const [text, setText] = useState('');

  const onChangeText = (event, { value }) => {
    setText(value);
  };

  const onSubmit = (event) => {
    // Hier die onSubmit-Logik aus deinem ursprünglichen Code einfügen
    const path =
      window.location.pathname.length > 0
        ? `&path=${encodeURIComponent(window.location.pathname)}`
        : '';
    window.location.href = `/search?SearchableText=${encodeURIComponent(text)}${path}`;
    // reset input value
    setText('');
    event.preventDefault();
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const handleKeyPress = (event) => {
    if (event.key === "/" || event.key === "Escape") {
      toggleSearch();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <Form
      action="/search"
      onSubmit={onSubmit}
      className={`search ${showSearch ? "show-search" : ""}`}
      id="search-bar"
    >
      <Input  
        aria-label={intl.formatMessage(messages.search)}
        onChange={onChangeText}
        name="SearchableText"
        value={text}
        transparent
        autoComplete="off"
        placeholder={intl.formatMessage(messages.searchSite)}
        title={intl.formatMessage(messages.search)}
      />
      <div
        className="search__button"
        id="search-button"
        onClick={toggleSearch}
      >
        <img
          className={`search__icon ${showSearch ? "hidden" : ""}`}
          src={searchicon}
          alt="search icon"
        />
        <img
          className={`search__close ${
            showSearch ? "" : "hidden"
          }`}
          src={searchclose}
          alt="search close"
        />
      </div>
    </Form>
  );
};

SearchWidget.propTypes = {
  intl: PropTypes.object.isRequired,
  showSearch: PropTypes.bool.isRequired,
  setShowSearch: PropTypes.func.isRequired,
};

const Header = ({ intl }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false); // Zustand für showSearch hinzugefügt
  const rootURL = useLocation().pathname.split('/')[1];
  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    if (!isMobileMenuOpen) {
      return;
    }
    setIsMobileMenuOpen(false);
  };

  return (
    <Segment basic className="header-wrapper" role="banner">
      <Container>
        <div id="nav-background" className="nav-background"></div>

        <header>
          <nav id="top-bar">
            <div className="logo">
              <UniversalLink
                href={settings.isMultilingual ? `/${toBackendLang(lang)}` : '/'}
                title={intl.formatMessage(messages.site)}
              >
                <Image
                  src={LogoImage}
                  alt={intl.formatMessage(messages.plonesite)}
                  title={intl.formatMessage(messages.plonesite)}
                />
                <span className={`claim ${showSearch ? "hide-claim" : ""}`}>International Coordination of<br/> Revolutionary Parties and Organizations</span>
              </UniversalLink>
            </div>

            <div className={`menu-languages ${showSearch ? "hide-languages" : ""}`}>
              <ul>
                <li><Link to="/en" title="English">EN</Link></li>
                <li><Link to="/de" title="Deutsch">DE</Link></li>
                <li><Link to="/es" title="Español">ES</Link></li>
                <li><Link to="/fr" title="Français">FR</Link></li>
                <li><Link to="/ru" title="Русский">РУ</Link></li>
                <li><Link to="/tr" title="Türkçe">TR</Link></li>
                <li><Link to="/pt" title="Português">PT</Link></li>
                <li><Link to="/ar" title="(Arabisch) العربية">الع</Link></li>
                <li><Link to="/fa" title="(Farsi) فارسی">فا</Link></li>
              </ul>
            </div>

            <div className="search-wrapper">
              <SearchWidget intl={intl} showSearch={showSearch} setShowSearch={setShowSearch} />
            </div>

            <div>
              <span>
                <div className="menu-toggle" onClick={toggleMobileMenu}>
                  <img
                    id="menu-hamburguer"
                    src={hamburguer}
                    alt="Open Menu"
                    style={{
                      display: !isMobileMenuOpen ? 'block' : 'none',
                    }}
                  />
                  <img
                    id="menu-close"
                    src={searchclose}
                    alt="Close Menu"
                    style={{
                      display: isMobileMenuOpen ? 'block' : 'none',
                    }}
                  />
                </div>
              </span>
            </div>
          </nav>

          <div
            className={`col-2 section-padding ${isMobileMenuOpen ? 'open' : ''}`}
            id="menu-screen"
            onClick={closeMobileMenu}
          >
            {rootURL === 'en' && (
              <>
                <div className="menu-screen__main-link">
                  <span><Link to="en/content/about-icor">About ICOR</Link></span>
                  <span><Link to="en/content/founding-resolution-of-the-icor">Founding Resolution</Link></span>
                  <span><Link to="en/content/statute-of-the-icor">Statut</Link></span>
                  <span><Link to="en/conferences">Conferences</Link></span>
                  <span><Link to="en/icor-official">ICOR Official</Link></span>
                </div>
                <div className="menu-screen__main-link">
                  <span><Link to="en/all">All articles</Link></span>
                  <span><Link to="en/resolutions">Resolutionen</Link></span>
                  <span><Link to="en/icor-members">Articles of the members</Link></span>
                  <span><Link to="en/guestcontributions">Guest articles</Link></span>
                </div>
              </>
            )}
            {rootURL === 'de' && (
              <>
                <div className="menu-screen__main-link">
                  <span><Link to="de/inhalte/ueber-die-icor">Über die ICOR</Link></span>
                  <span><Link to="de/inhalte/gruendungsresolution-der-icor">Gründungsresolution</Link></span>
                  <span><Link to="de/inhalte/statut-der-icor">Statut</Link></span>
                  <span><Link to="de/konferenzen">Konferenzen</Link></span>
                  <span><Link to="de/icor-official">ICOR Offiziell</Link></span>
                </div>
                <div className="menu-screen__main-link">
                  <span><Link to="de/all">Alle Artikel</Link></span>
                  <span><Link to="de/resolutions">Resolutionen</Link></span>
                  <span><Link to="de/icor-member">Artikel der Mitglieder</Link></span>
                  <span><Link to="de/guestcontributions">Gastartikel</Link></span>
                </div>
              </>
            )}
            {rootURL === 'es' && (
              <>
                <div className="menu-screen__main-link">
                  <span><Link to="es/contenido/acerca-de-icor">Acerca de ICOR</Link></span>
                  <span><Link to="es/contenido/resolucion-de-fundacion-de-la-icor">Resolución de Fundación</Link></span>
                  <span><Link to="es/contenido/estatuto-de-la-icor">Estatuto de la ICOR</Link></span>
                  <span><Link to="es/conferencias">Conferencias</Link></span>
                  <span><Link to="es/icor-official">ICOR Oficial</Link></span>
                </div>
                <div className="menu-screen__main-link">
                  <span><Link to="es/all">Todo el contenido</Link></span>
                  <span><Link to="es/resolutions">Resoluciones</Link></span>
                  <span><Link to="es/icor-member">Contribuciones de los miembros</Link></span>
                  <span><Link to="es/guestcontributions">Artículo invitado</Link></span>
                </div>
              </>
            )}
            {rootURL === 'fr' && (
              <>
                <div className="menu-screen__main-link">
                  <span><Link to="fr/contenu/sur-licor">Sur l'ICOR</Link></span>
                  <span><Link to="fr/contenu/resolution-de-fondation-de-licor">Résolution de fondation</Link></span>
                  <span><Link to="fr/contenu/statuts-de-licor">Statuts de l’ICOR</Link></span>
                  <span><Link to="fr/conferences">Conférences</Link></span>
                  <span><Link to="fr/icor-official">ICOR Officiel</Link></span>
                </div>
                <div className="menu-screen__main-link">
                  <span><Link to="fr/all">Tout le contenu</Link></span>
                  <span><Link to="fr/resolutions">Résolutions</Link></span>
                  <span><Link to="fr/icor-member">Contributions des membres</Link></span>
                  <span><Link to="fr/guestcontributions">Articles invité</Link></span>
                </div>
              </>
            )}
            {rootURL === 'pt' && (
              <>
                <div className="menu-screen__main-link">
                  <span><Link to="pt/2010/estatuto-da-icor">Estatuto da ICOR</Link></span>
                </div>
                <div className="menu-screen__main-link">
                  <span><Link to="pt/resolutions">Resolução</Link></span>
                  <span><Link to="pt/all">Todos os artigos</Link></span>
                  <span><Link to="pt/icor-member">Artigos dos membros</Link></span>
                  <span><Link to="pt/guestcontributions">Artigos de convidado</Link></span>
                </div>
              </>
            )}
            {rootURL === 'ru' && (
              <>
                <div className="menu-screen__main-link">
                  <span><Link to="ru/soderzhanie/po-ikor">По ИКОР</Link></span>
                  <span><Link to="ru/soderzhanie/uchreditelnaya-rezolyuciya-icor">Учредительная резолюция</Link></span>
                  <span><Link to="ru/soderzhanie/ustav-icor">Устав ICOR</Link></span>
                  <span><Link to="ru/konferencii">Конференции</Link></span>
                  <span><Link to="ru/icor-official">Официальное лицо ICOR</Link></span>
                </div>
                <div className="menu-screen__main-link">
                  <span><Link to="ru/all">Все статьи</Link></span>
                  <span><Link to="ru/resolutions">Резолюции</Link></span>
                  <span><Link to="ru/icor-member">Взносы членов</Link></span>
                  <span><Link to="ru/guestcontributions">гостевая статья</Link></span>
                </div>
              </>
            )}
            {rootURL === 'tr' && (
              <>
                <div className="menu-screen__main-link">
                  <span><Link to="tr/2010/icor-kurulus-karari">Kuruluş Kararı</Link></span>
                  <span><Link to="tr/konferanslar">Konferanslar</Link></span>
                  <span><Link to="tr/icor-official">ICOR Yetkilisi</Link></span>
                </div>
                <div className="menu-screen__main-link">
                  <span><Link to="tr/all">Tüm makaleler</Link></span>
                  <span><Link to="tr/resolutions">Kararları</Link></span>
                  <span><Link to="tr/icor-member">ICOR üyelerinin katkıları</Link></span>
                  <span><Link to="tr/guestcontributions">Konuk makale</Link></span>
                </div>
              </>
            )}
            {rootURL === 'ar' && (
              <>
                <div className="menu-screen__main-link">
                  <span><Link to="mwtmrt-ykwr">مؤتمرات ايكور</Link></span>
                </div>
                <div className="menu-screen__main-link">
                  <span><Link to="ar/all">جميع البنود</Link></span>
                  <span><Link to="ar/resolutions">قرارات</Link></span>
                  <span><Link to="ar/icor-member">مساهمات لأعضاء إيكور</Link></span>
                  <span><Link to="ar/guestcontributions">مساهمات الضيوف</Link></span>
                </div>
              </>
            )}
            {rootURL === 'fa' && (
              <>
                <div className="menu-screen__main-link">
                  <span><Link to="fa/content/qt-nmhy-tsys-ykhwr">اساسنامۀ ایکور</Link></span>
                  <span><Link to="fa/content/ssnmhy-ykhwr">قطعنامه تاسیس</Link></span>
                  <span><Link to="fa/hmysh-h">همایش ها</Link></span>
                </div>
                <div className="menu-screen__main-link">
                  <span><Link to="fa/all">همه مطالب</Link></span>
                  <span><Link to="fa/resolutions">قطعنامه ها</Link></span>
                  <span><Link to="fa/icor-member">مقالات اعضا</Link></span>
                  <span><Link to="fa/guestcontributions">پست های مهمان</Link></span>
                </div>                
              </>
            )}
            <div className="white-overlay"></div>
            <div className="menu-footer">
              <div className="footer__link"><Link to="/en" title="English">EN</Link></div>
              <div className="footer__link"><Link to="/de" title="Deutsch">DE</Link></div>
              <div className="footer__link"><Link to="/es" title="Español">ES</Link></div>
              <div className="footer__link"><Link to="/fr" title="Français">FR</Link></div>
              <div className="footer__link"><Link to="/ru" title="Русский">РУ</Link></div>
              <div className="footer__link"><Link to="/tr" title="Türkçe">TR</Link></div>
              <div className="footer__link"><Link to="/pt" title="Português">PT</Link></div>
              <div className="footer__link"><Link to="/ar" title="(Arabisch) العربية">الع</Link></div>
              <div className="footer__link"><Link to="/fa" title="(Farsi) فارسی">فا</Link></div>
            </div>
          </div>
        </header>
      </Container>
    </Segment>
  );
};
Header.propTypes = {
  intl: PropTypes.object.isRequired, // Make sure intl is required as a prop
};

export default compose(withRouter, injectIntl)(Header);