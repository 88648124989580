/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';
import {
  settings as defaultSettings,
  views as defaultViews,
  widgets as defaultWidgets,
  tiles as defaultTiles,
} from '@plone/volto/config';
import { StartpageView } from './components';
import config from '@plone/volto/registry'

export default function applyConfig(config) {
  config.settings = {
    ...config.settings,
    isMultilingual: true,
    supportedLanguages: ['en' , 'de', 'es' , 'fr', 'tr', 'ru' , 'ar', 'fa' , 'pt' ],
    defaultLanguage: 'en',
    matomoSiteId: '3',
    matomoUrlBase: 'https://stats.mlpd.de/',
  };
  return config;
}
