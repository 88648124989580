/**
 * Tabular view component.
 * @module components/theme/View/TabularView
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import { Container as SemanticContainer, Segment, Image, Table } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import config from '@plone/volto/registry';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import { searchContent } from '@plone/volto/actions';
import { FormattedDate } from '@plone/volto/components';
import { Link } from 'react-router-dom';

const messages = defineMessages({
  allContent: {
    id: 'All content',
    defaultMessage: 'All content',
  }
});

/**
 * MasonryLayout component.
 */
const MasonryLayout = ({ children }) => {
  const [columns, setColumns] = useState(3); // Anfangs 3 Spalten, du kannst dies anpassen

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1200) {
        setColumns(3);
      } else if (window.innerWidth >= 768) {
        setColumns(2);
      } else {
        setColumns(1);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Initialisieren der Spaltenanzahl basierend auf der Fensterbreite

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const columnArray = new Array(columns).fill(0);

  const columnsContent = columnArray.map((_, columnIndex) => {
    return (
      <div key={columnIndex} style={{ flex: `1 0 calc(100% / ${columns})` }}>
        {children.filter((_, index) => index % columns === columnIndex)}
      </div>
    );
  });

  return (
    <div className="masonrybox" style={{ display: 'flex', flexWrap: 'wrap' }}>
      {columnsContent}
    </div>
  );
};

/**
 * Tabular view component class.
 * @function TabularView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const TabularView = ({ content, intl }) => {
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;

  const dispatch = useDispatch();

  const [searchResults, setSearchResults] = useState([]);

  const languageRoot = content['language'].token;

  useEffect(() => {
    dispatch(
      searchContent(languageRoot, {
        portal_type: ['News Item'],
        review_state: 'published',
        sort_on: 'effective',
        sort_order: 'reverse',    
        Subject: content.description,
        b_size: 100,
        fullobjects: true,
      }),
      'search'
    ).then((response) => {
      setSearchResults(response.items);
    });
  }, [dispatch, content.id]);

  return (
    <div className="view-wrapper">
      <section className="section-padding">
        <header>
          <h2 className="section-title--small section-title">
            { content.title.split(' ')[0] }  <span className="headline-variation">{ content.title.split(' ').slice(1).join(' ') }</span>
          </h2>
        </header>
        <section id="content-core">
          <MasonryLayout>
            {searchResults &&
              searchResults.map((item) => (
                <div key={item.id} className="card__link white-card white-card-sm">
                  <span className="card__article-subject">{item.preline}</span>
                  <h3 className="headline-sm">
                    <Link to={item['@id'].split('/').slice(3).join('/')} title={item.title}>
                      {item.title}
                    </Link>
                  </h3>
                  <div className="card__author_and_date">
                    {item.authororganisation && (
                      <span className="card__author">{item.authororganisation}, </span>
                    )}
                    {item.review_state === 'published' && item.effective && (
                      <span className="card__date">
                        &nbsp;<FormattedDate date={ item.document_date ? item.document_date : item.effective } />
                      </span>
                    )}
                  </div>
                </div>
              ))}
          </MasonryLayout>
          <div className="read-more">
                <Link to={content.id + '/more'}>{intl.formatMessage(messages.allContent)}</Link>
          </div>
        </section>
      </section>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
TabularView.propTypes = {
  /**
   * Content of the object
   */
  content: PropTypes.shape({
    /**
     * Title of the object
     */
    title: PropTypes.string,
    /**
     * Description of the object
     */
    description: PropTypes.string,
    /**
     * Child items of the object
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Title of the item
         */
        title: PropTypes.string,
        /**
         * Description of the item
         */
        description: PropTypes.string,
        /**
         * Url of the item
         */
        url: PropTypes.string,
        /**
         * Review state of the item
         */
        review_state: PropTypes.string,
        /**
         * Type of the item
         */
        '@type': PropTypes.string,
      }),
    ),
  }).isRequired,
};

TabularView.propTypes = {
  content: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default compose(injectIntl)(TabularView);
