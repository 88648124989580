import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer, Segment, Image } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { searchContent } from '@plone/volto/actions';
import config from '@plone/volto/registry';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { FormattedDate } from '@plone/volto/components';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {
  flattenToAppURL,
} from '@plone/volto/helpers';


// Importing images for hero animation
import heroBg from "../../../../../public/assets/hero-bg.jpg";
import heroHand1 from "../../../../../public/assets/hero-image_hand_01.png";
import heroHand2 from "../../../../../public/assets/hero-image_hand_02.png";
import heroHand3 from "../../../../../public/assets/hero-image_hand_03.png";
import heroHand4 from "../../../../../public/assets/hero-image_hand_04.png";
import uniteImg from "../../../../../public/assets/unite-01.svg";


function AlbumView({ content, intl }) { 
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;

  const Topic1Subject = "Lenin_100";
  const Topic2Subject = "Palestine";
  const Topic3Subject = "Ukraine";


  // Hier kommt der useEffect-Abschnitt für die Animation
  useEffect(() => {
 //   gsap.registerPlugin(ScrollTrigger);

    let heroHeight = document.querySelector(".hero").offsetHeight / 5;

  /*  let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".hero",
        start: "top top",
        end: () => `+=${heroHeight}`,
        scrub: true,
      },
    });

    const getRandomNumber = () => {
      const randomNumber = Math.random();
      if (randomNumber < 0.45) {
        return 0;
      } else if (randomNumber < 0.75) {
        return 0.3;
      } else {
        return 0.5;
      }
    };

    for (let i = 1; i <= 4; i++) {
      tl.to(
        `#hero-hand-${i}`,
        {
          top: 0,
        },
        getRandomNumber()
      );
    }*/
  }, []);
  
  const hands = [heroHand1, heroHand2, heroHand3, heroHand4];


  const messages = defineMessages({
    allContent: {
      id: 'All content',
      defaultMessage: 'All content',
    },
    icorResolutions: {
      id: 'ICOR Resolutions',
      defaultMessage: 'ICOR Resolutions',
    },
    icorOfficial: {
      id: 'ICOR Official',
      defaultMessage: 'ICOR Official',
    },
    contributions: {
      id: 'Contributions',
      defaultMessage: 'Contributions from ICOR members',
    },
    unitedfront: {
      id: 'UnitedFront',
      defaultMessage: 'International Anti-Imperialist Anti-Fascist United Front',
    },
    guestcontributions: {
      id: 'GuestContributions',
      defaultMessage: 'Guest contributions',
    },
    more: {
      id: 'More',
      defaultMessage: 'more',
    },
    toWebsite: {
      id: 'toWebsite',
      defaultMessage: 'To the website',
    },
  });
  
  const dispatch = useDispatch();

  // Zustandsvariablen für die Ergebnisse
  const [searchTopResolutionResults, setSearchTopResolutionResults] = useState([]);
  const [searchTopArticleResults, setSearchTopArticleResults] = useState([]);
  const [searchResolutionsResults, setSearchResolutionsResults] = useState([]);
  const [searchActualTopic1Results, setsearchActualTopic1Results] = useState([]);
  const [searchActualTopic1ArticleResults, setsearchActualTopic1ArticleResults] = useState([]);
  const [searchActualTopic2ArticleResults, setsearchActualTopic2ArticleResults] = useState([]);
  const [searchActualTopic3ArticleResults, setsearchActualTopic3ArticleResults] = useState([]);
  const [searchIcorMemberArticleResults, setsearchIcorMemberArticleResults] = useState([]);
  const [searchIcorOfficialArticleResults, setsearchIcorOfficialArticleResults] = useState([]);
  const [searchGuestArticleResults, setsearchGuestArticleResults] = useState([]);

  useEffect(() => {
    dispatch(
      searchContent(content.id, {
        portal_type: ['News Item'],
        review_state: 'published',
        sort_on: 'effective',
        sort_order: 'reverse',    
        Subject: 'Resolutions',
        b_size: 1,
        fullobjects: true,
      }),
      'searchTopResolution'
    ).then((response) => {
      setSearchTopResolutionResults(response.items);
    });
    dispatch(
      searchContent(content.id, {
        portal_type: ['News Item'],
        review_state: 'published',
        sort_on: 'effective',
        sort_order: 'reverse',    
        Subject: 'Toparticle',
        b_size: 1,
        fullobjects: true,
      }),
      'searchTopArticle'
    ).then((response) => {
      setSearchTopArticleResults(response.items);
    });

    dispatch(
      searchContent(content.id, {
        portal_type: ['News Item'],
        review_state: 'published',
        sort_on: 'effective',
        sort_order: 'reverse',
        Subject: 'Resolutions',
        b_size: 3,
        fullobjects: true,
      }),
      'searchResolutions'
    ).then((response) => {
      setSearchResolutionsResults(response.items);
    });

    dispatch(
      searchContent(content.id, {
        portal_type: ['focus_topic'],
        review_state: 'published',
        sort_on: 'effective',
        sort_order: 'reverse',
        b_size: 3,
        fullobjects: true,
      }),
      'searchActualTopic1'
    ).then((response) => {
      setsearchActualTopic1Results(response.items);
    });
    dispatch(
      searchContent(content.id, {
        portal_type: ['News Item'],
        review_state: 'published',
        sort_on: 'effective',
        sort_order: 'reverse',
        Subject: Topic1Subject,
        b_size: 3,
        fullobjects: true,
      }),
      'searchActualTopic1Article'
    ).then((response) => {
      setsearchActualTopic1ArticleResults(response.items);
    });
    dispatch(
      searchContent(content.id, {
        portal_type: ['News Item'],
        review_state: 'published',
        sort_on: 'effective',
        sort_order: 'reverse',
        Subject: Topic2Subject,
        b_size: 3,
        fullobjects: true,
      }),
      'searchActualTopic2Article'
    ).then((response) => {
      setsearchActualTopic2ArticleResults(response.items);
    });
    dispatch(
      searchContent(content.id, {
        portal_type: ['News Item'],
        review_state: 'published',
        sort_on: 'effective',
        sort_order: 'reverse',
        Subject: Topic3Subject,
        b_size: 3,
        fullobjects: true,
      }),
      'searchActualTopic3Article'
    ).then((response) => {
      setsearchActualTopic3ArticleResults(response.items);
    });
    dispatch(
      searchContent(content.id, {
        portal_type: ['News Item'],
        review_state: 'published',
        sort_on: 'effective',
        sort_order: 'reverse',
        Subject: 'ICOR Member',
        b_size: 4,
        fullobjects: true,
      }),
      'searchIcorMemberArticle'
    ).then((response) => {
      setsearchIcorMemberArticleResults(response.items);
    });
    dispatch(
      searchContent(content.id, {
        portal_type: ['News Item'],
        review_state: 'published',
        sort_on: 'effective',
        sort_order: 'reverse',
        Subject: 'ICOR Official',
        b_size: 3,
        fullobjects: true,
      }),
      'searchIcorOfficialArticle'
    ).then((response) => {
      setsearchIcorOfficialArticleResults(response.items);
    });
    dispatch(
      searchContent(content.id, {
        portal_type: ['News Item'],
        review_state: 'published',
        sort_on: 'effective',
        sort_order: 'reverse',
        Subject: 'Guest',
        b_size: 4,
        fullobjects: true,
      }),
      'searchGuestArticle'
    ).then((response) => {
      setsearchGuestArticleResults(response.items);
    });
  }, [dispatch, content.id]);

  return (
    <div>
        <article id="content">

        <div className="hero">
          <div className="hero-image">
            <div className="hero-image__animation-asset" id="hero-bg">
              <img src={heroBg} alt="ICOR" />
            </div>
            {hands.map((hand, index) => (
              <div
                key={index}
                className="hero-image__animation-asset"
                id={`hero-hand-${index + 1}`}
              >
                <img src={hand} alt="ICOR" />
              </div>
            ))}
          </div>
          <div className="red-overlay"></div>
          <div className="hero-headlines_wrapper">
            <div className="hero-headlines">
              <img src={uniteImg} alt="ICOR" />
            </div>
          </div>
        </div>


        {searchTopArticleResults && searchTopArticleResults.length > 0 && (
          <section className="toparticle-row section-padding">
            <div className="col-2-1">
              <div className="top__article_picture">
                {searchTopArticleResults.map((item) => (
                  <Image
                    key={item.id}
                    alt={item.title}
                    title={item.title}
                    src={
                      item.image['content-type'] === 'image/svg+xml'
                        ? flattenToAppURL(item.image.download)
                        : flattenToAppURL(item.image.scales.large.download)
                    }
                    floated="right"
                  />
                ))}
              </div>
              <div className="top__article_side">
                {searchTopArticleResults.map((item) => (
                  <Segment basic key={item.id}>
                    {item.preline && (
                      <span className="card__article-subject">{item.preline}</span>
                    )}
                    <h3 className="headline-lg">
                      <Link
                        to={item['@id'].split('/').slice(3).join('/')}
                        title={item.title}
                      >
                        {item.title}
                      </Link>
                    </h3>
                    <div>
                      {item.authororganisation && (
                        <span className="card__author">{item.authororganisation}, </span>
                      )}
                      {item.review_state === 'published' && item.effective && (
                        <span className="card__date">
                          &nbsp;<FormattedDate date={ item.document_date ? item.document_date : item.effective } />
                        </span>
                      )}
                      <div className="top__article_description">
                          <p>{item.description}</p>
                      </div>
                      <div className="read-more">
                        <Link
                          to={item['@id'].split('/').slice(3).join('/')}
                          title={item.title}
                        >
                          {intl.formatMessage(messages.more)}
                        </Link>
                      </div>
                    </div>
                  </Segment>
                ))}
              </div>
            </div>
          </section>
        )}

          
          <section className="resolutions-row section-padding">
            <h1 className="section-title--big section-title">{intl.formatMessage(messages.icorResolutions).split(' ')[0]} <span className="headline-variation">{intl.formatMessage(messages.icorResolutions).split(' ').slice(1).join(' ')}</span></h1>
              <div className="col-2-1">
                <div className="top-story__card white-card white-card-lg">
                  {searchTopResolutionResults &&
                      searchTopResolutionResults.map((item) => (
                      <Segment basic key={item.id}>
                        {item.preline && (
                          <span className="card__article-subject">{item.preline}</span>
                        )}
                        <h3 className="headline-lg">
                          <Link to={item['@id'].split('/').slice(3).join('/')} title={item.title}>{item.title}</Link>
                        </h3> 
                        <div className="card__author_and_date">
	                        {item.authororganisation && (
                              <span className="card__author">{item.authororganisation}, 
		                          </span>
                          )}
	                        {item.review_state === 'published' && item.effective && (
	                          <span className="card__date">
                              &nbsp;<FormattedDate date={ item.document_date ? item.document_date : item.effective }  />
                            </span>
	                         )}
	                      </div>
                        <p>
                          { item.description }
                        </p>
                      </Segment>
                    ))}
                </div>
                <div className="side-stories">
                  {searchResolutionsResults &&
                    searchResolutionsResults.slice(1, 4).map((item) => (
                    <Segment basic key={item.id} className="card__link">
                        {item.preline && (
                          <span className="card__article-subject">{item.preline}</span>
                        )}
                        <h3 className="headline-sm">
                          <Link to={item['@id'].split('/').slice(3).join('/')} title={item.title}>{item.title}</Link>
                        </h3> 
                        <div className="card__author_and_date">
	                        {item.authororganisation && (
                              <span className="card__author">{item.authororganisation}, 
		                          </span>
                          )}
	                        {item.review_state === 'published' && item.effective && (
	                          <span className="card__date">
                              &nbsp;<FormattedDate date={ item.document_date ? item.document_date : item.effective }  />
                            </span>
	                         )}
	                      </div>
                      </Segment>
                    ))}
                </div>

              </div>
              <div className="read-more">
                <Link to={content.id + '/resolutions'}>{intl.formatMessage(messages.allContent)}</Link>
              </div>
          </section>
          <div className="intersection">
            <img src="./assets/icor-resolution.jpg" alt="ICOR Resolutions" />
            <div className="white-overlay"></div>
          </div>
          
          <section className="actual-topic-row">
            {searchActualTopic1Results.slice(1, 2) &&
                      searchActualTopic1Results.slice(0, 1).map((item) => (
                      <Segment basic key={item.id}>
                        <div className="actual-topic-row__content section-padding">
                          <h2 className="section-title--big section-title">
                            <Link to={'/' + content.id + '/' + Topic1Subject.toLowerCase()} title={item.title}>{item.title.split(' ')[0]} <span className="headline-variation">{item.title.split(' ').slice(1).join(' ')}</span></Link>
                          </h2>
                          <div className="col-3">
                          {searchActualTopic1ArticleResults &&
                              searchActualTopic1ArticleResults.map((item2) => (
                            <div className="card__link" key={item2.id}>
                              <span className="card__article-subject">{item2.preline}</span>
                              <h3 className="headline-sm">
                                <Link to={item2['@id'].split('/').slice(3).join('/')} title={item2.title}>{item2.title}</Link>
                              </h3>
                              <div className="card__author_and_date">
                              {item2.authororganisation && (
                              <span className="card__author">{item2.authororganisation}, 
		                          </span>
                                )}
	                              {item2.review_state === 'published' && item2.effective && (
	                                <span className="card__date">
                                  &nbsp;<FormattedDate  date={ item2.document_date ? item2.document_date : item2.effective }  />
                                </span>
	                         )}
                                </div>
                            </div>
                          ))}
                          </div>   
                          <div className="read-more">
                              {item.link1 && (  
                                  <a href={item.link1} style={{ marginRight: '10px' }}>{item.linktext1}</a>
                              )}
                              {item.link2 && (  
                                  <a href={item.link2} style={{ marginRight: '10px' }}>{item.linktext2} </a>
                              )}
                              {item.link3 && (  
                                  <a href={item.link3} style={{ marginRight: '10px' }}>{item.linktext3} </a>
                              )}
                              <Link to={'/' + content.id + '/' + Topic1Subject.toLowerCase()}>{intl.formatMessage(messages.allContent)}</Link>
                          </div>                                                
                      </div>                         

                      <div className="actual-topic-row__bg-image">

                        <Image
                          alt={item.title}
                          title={item.title}
                          src={`${item.wallpaper.download}`}
                          /> 
                          <div className="red-overlay"></div>
                        </div>
                      </Segment>
                      ))}
             </section>

             <section className="mitglider-row section-padding">
                <h2 className="section-title--small section-title">
                    <Link to={content.id + '/icor-member'}>
                      {intl.formatMessage(messages.contributions).split(' ')[0]} <span className="headline-variation">{intl.formatMessage(messages.contributions).split(' ').slice(1).join(' ')}</span>
                    </Link>
                </h2>
                <div className="col-4">
                {searchIcorMemberArticleResults &&
                              searchIcorMemberArticleResults.map((item) => (
                  <div className="card__link" key={item.id}>
                      <span className="card__article-subject">{item.preline}</span>
                      <h3 className="headline-sm">
                                <Link to={item['@id'].split('/').slice(3).join('/')} title={item.title}>{item.title}</Link>
                       </h3>                    
                       <div className="card__author_and_date">
	                        {item.authororganisation && (
                              <span className="card__author">{item.authororganisation}, 
		                          </span>
                          )}
	                        {item.review_state === 'published' && item.effective && (
	                          <span className="card__date">
                              &nbsp;<FormattedDate date={ item.document_date ? item.document_date : item.effective }  />
                            </span>
	                         )}
	                      </div>
                  </div>
                  ))}
                 </div>
                 <div className="read-more">
                      <Link to={content.id + '/icor-member'}>{intl.formatMessage(messages.allContent)}</Link>
                  </div>            
              </section>  
              <div className="intersection">
                <img src="./assets/icor-members.jpg" alt="ICOR Resolutions" />
                <div className="white-overlay"></div>
              </div>


              <section className="actual-topic-row">
            {searchActualTopic1Results.slice(1, 2) &&
                      searchActualTopic1Results.slice(1, 2).map((item) => (
                      <Segment basic key={item.id}>
                        <div className="actual-topic-row__content section-padding">
                          <h2 className="section-title--big section-title">
                            <Link to={'/' + content.id + '/' + Topic2Subject.toLowerCase()} title={item.title}>{item.title.split(' ')[0]} <span className="headline-variation">{item.title.split(' ').slice(1).join(' ')}</span></Link>
                          </h2>
                          <div className="col-3">
                          {searchActualTopic2ArticleResults &&
                              searchActualTopic2ArticleResults.map((item2) => (
                            <div className="card__link" key={item2.id}>
                              <span className="card__article-subject">{item2.preline}</span>
                              <h3 className="headline-sm">
                                <Link to={item2['@id'].split('/').slice(3).join('/')} title={item2.title}>{item2.title}</Link>
                              </h3>
                              <div className="card__author_and_date">
                              {item2.authororganisation && (
                              <span className="card__author">{item2.authororganisation}, 
		                          </span>
                                )}
	                              {item2.review_state === 'published' && item2.effective && (
	                                <span className="card__date">
                                  &nbsp;<FormattedDate  date={ item2.document_date ? item2.document_date : item2.effective }  />
                                </span>
	                         )}
                                </div>
                            </div>
                          ))}
                          </div>   
                          <div className="read-more">
                              {item.link1 && (  
                                  <a href={item.link1} style={{ marginRight: '10px' }}>{item.linktext1}</a>
                              )}
                              {item.link2 && (  
                                  <a href={item.link2} style={{ marginRight: '10px' }}>{item.linktext2} </a>
                              )}
                              {item.link3 && (  
                                  <a href={item.link3} style={{ marginRight: '10px' }}>{item.linktext3} </a>
                              )}
                              <Link to={'/' + content.id + '/' + Topic2Subject.toLowerCase()}>{intl.formatMessage(messages.allContent)}</Link>
                          </div>                                                
                      </div>                         

                      <div className="actual-topic-row__bg-image">

                        <Image
                          alt={item.title}
                          title={item.title}
                          src={`${item.wallpaper.download}`}
                          /> 
                          <div className="red-overlay"></div>
                        </div>
                      </Segment>
                      ))}
             </section>


              <section className="icor-offical-row section-padding">
                <h2 className="section-title--small section-title">
                    <Link to={content.id + '/icor-official'}>
                      {intl.formatMessage(messages.icorOfficial).split(' ')[0]} <span className="headline-variation">{intl.formatMessage(messages.icorOfficial).split(' ').slice(1).join(' ')}</span>
                    </Link>
                </h2>
                <div className="col-3">
                {searchIcorOfficialArticleResults &&
                              searchIcorOfficialArticleResults.map((item) => (
                  <div className="card__link white-card white-card-sm" key={item.id}>
                      <span className="card__article-subject">{item.preline}</span>
                      <h3 className="headline-sm">
                                <Link to={item['@id'].split('/').slice(3).join('/')} title={item.title}>{item.title}</Link>
                      </h3> 
                      <div>
	                        {item.authororganisation && (
                              <span className="card__author">{item.authororganisation}, 
		                          </span>
                          )}
	                        {item.review_state === 'published' && item.effective && (
	                          <span className="card__date">
                              &nbsp;<FormattedDate date={ item.document_date ? item.document_date : item.effective }  />
                            </span>
	                         )}
	                      </div>
                    </div>
                                ))}
                </div>
                <div className="read-more">
                      <Link to={content.id + '/icor-official'}>{intl.formatMessage(messages.allContent)}</Link>
                </div>   
              </section>

              <section className="actual-topic-row">
            {searchActualTopic1Results.slice(2, 3) &&
                      searchActualTopic1Results.slice(2, 3).map((item) => (
                      <Segment basic key={item.id}>
                        <div className="actual-topic-row__content section-padding">
                          <h2 className="section-title--big section-title">
                            <Link to={'/' + content.id + '/' + Topic3Subject.toLowerCase()} title={item.title}>{item.title.split(' ')[0]} <span className="headline-variation">{item.title.split(' ').slice(1).join(' ')}</span></Link>
                          </h2>
                          <div className="col-3">
                          {searchActualTopic3ArticleResults &&
                              searchActualTopic3ArticleResults.map((item2) => (
                            <div className="card__link" key={item2.id}>
                              <span className="card__article-subject">{item2.preline}</span>
                              <h3 className="headline-sm">
                                <Link to={item2['@id'].split('/').slice(3).join('/')} title={item2.title}>{item2.title}</Link>
                              </h3>
                              <div className="card__author_and_date">
                              {item2.authororganisation && (
                              <span className="card__author">{item2.authororganisation}, 
		                          </span>
                                )}
	                              {item2.review_state === 'published' && item2.effective && (
	                                <span className="card__date">
                                  &nbsp;<FormattedDate  date={ item2.document_date ? item2.document_date : item2.effective }  />
                                </span>
	                         )}
                                </div>
                            </div>
                          ))}
                          </div>   
                          <div className="read-more">
                              {item.link1 && (  
                                  <a href={item.link1} style={{ marginRight: '10px' }}>{item.linktext1}</a>
                              )}
                              {item.link2 && (  
                                  <a href={item.link2} style={{ marginRight: '10px' }}>{item.linktext2} </a>
                              )}
                              {item.link3 && (  
                                  <a href={item.link3} style={{ marginRight: '10px' }}>{item.linktext3} </a>
                              )}
                              <Link to={'/' + content.id + '/' + Topic3Subject.toLowerCase()}>{intl.formatMessage(messages.allContent)}</Link>
                          </div>                                                
                      </div>                         

                      <div className="actual-topic-row__bg-image">

                        <Image
                          alt={item.title}
                          title={item.title}
                          src={`${item.wallpaper.download}`}
                          /> 
                          <div className="red-overlay"></div>
                        </div>
                      </Segment>
                      ))}
             </section>

              <section className="guest-row section-padding">
                <h2 className="section-title--small section-title">
                <Link to={content.id + '/guestcontributions'}>
                      {intl.formatMessage(messages.guestcontributions).split(' ')[0]}<span className="headline-variation">{intl.formatMessage(messages.guestcontributions).split(' ').slice(1).join(' ')}</span>
                </Link>
                </h2>
                <div className="col-4">
                {searchGuestArticleResults &&
                              searchGuestArticleResults.map((item) => (
                  <div className="card__link" key={item.id}>
                      <span className="card__article-subject">{item.preline}</span>
                      <h3 className="headline-sm">
                                <Link to={item['@id'].split('/').slice(3).join('/')} title={item.title}>{item.title}</Link>
                      </h3> 
                      <div className="card__author_and_date">
	                        {item.authororganisation && (
                              <span className="card__author">{item.authororganisation}, 
		                          </span>
                          )}
	                        {item.review_state === 'published' && item.effective && (
	                          <span className="card__date">
                              &nbsp;<FormattedDate date={ item.document_date ? item.document_date : item.effective }  />
                            </span>
	                         )}
	                      </div>
                    </div>
                  ))}
                </div>
                <div className="read-more">
                      <Link to={content.id + '/guestcontributions'}>{intl.formatMessage(messages.allContent)}</Link>
                </div> 
				</section>

              <section className="anti-imperialist-row">
                <div className="anti-imperialist-row__content section-padding">
                  <div className="col-2-1">
                    <div className="card-link">
                      <h2 className="section-title--big section-title">
                        <span className="headline-variation">United</span> Front
                      </h2>
                      <h3 className="headline-sm">{intl.formatMessage(messages.unitedfront)}</h3>
                      <h3 className="headline-sm anti-imperialist-row__link">
                        <a href='http://www.united-front.info' target="_blank" className="button-weiss-rot">{intl.formatMessage(messages.toWebsite)}</a>
                      </h3>
                    </div>
                    <div className="card-link">
                      <img src="./assets/united-front-logo.png" alt="United Front Logo" />
                    </div>
                  </div>
                  
                </div>

                <div className="anti-imperialist-row__bg-image">
                    <img src="./assets/united-front.jpg" alt="United Front" />
                <div className="red-overlay"></div>
                </div>

              </section>
        </article>

    </div>
  );
}

AlbumView.propTypes = {
  content: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default compose(injectIntl)(AlbumView);
