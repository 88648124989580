/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import eeacmsvoltoAccordionBlock from '@eeacms/volto-accordion-block';
import kitconceptvoltoBlocksGrid from '@kitconcept/volto-blocks-grid';
import kitconceptvoltoSliderBlock from '@kitconcept/volto-slider-block';
import eeacmsvoltoMatomo from '@eeacms/volto-matomo';

const addonsInfo = [{"modulePath":"/home/users/mlpd/Plone6_icor/icor/frontend/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/home/users/mlpd/Plone6_icor/icor/frontend/node_modules/@plone/volto/packages/volto-slate/package.json","version":"16.22.0","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"name":"@eeacms/volto-accordion-block","version":"9.3.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/home/users/mlpd/Plone6_icor/icor/frontend/node_modules/@eeacms/volto-accordion-block/src","packageJson":"/home/users/mlpd/Plone6_icor/icor/frontend/node_modules/@eeacms/volto-accordion-block/package.json","addons":[]},{"name":"@kitconcept/volto-blocks-grid","version":"8.0.3","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/home/users/mlpd/Plone6_icor/icor/frontend/node_modules/@kitconcept/volto-blocks-grid/src","packageJson":"/home/users/mlpd/Plone6_icor/icor/frontend/node_modules/@kitconcept/volto-blocks-grid/package.json","addons":[]},{"name":"@kitconcept/volto-slider-block","version":"4.3.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/home/users/mlpd/Plone6_icor/icor/frontend/node_modules/@kitconcept/volto-slider-block/src","packageJson":"/home/users/mlpd/Plone6_icor/icor/frontend/node_modules/@kitconcept/volto-slider-block/package.json","addons":[]},{"name":"@eeacms/volto-matomo","version":"4.1.3","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/home/users/mlpd/Plone6_icor/icor/frontend/node_modules/@eeacms/volto-matomo/src","packageJson":"/home/users/mlpd/Plone6_icor/icor/frontend/node_modules/@eeacms/volto-matomo/package.json","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, eeacmsvoltoAccordionBlock, kitconceptvoltoBlocksGrid, kitconceptvoltoSliderBlock, eeacmsvoltoMatomo];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
