/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { map } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import { Link, useLocation } from 'react-router-dom';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
  contact: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { settings } = config;
  const { lang, siteActions = [] } = useSelector(
    (state) => ({
      lang: state.intl.locale,
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  const rootURL = useLocation().pathname.split('/')[1];

  return (
    <footer role="contentinfo">
       <div className="footer__link">
        <Link to="/contact-form">{intl.formatMessage(messages.contact)}</Link>
      </div>
      {rootURL === 'de' ? (
      <>
        <div className="footer__link">
        <Link to="/de/inhalte/impressum">Impressum</Link>
        </div>
        <div className="footer__link">
          <a href="https://archive.icor.info/de" target='blank'>
            Archiv
          </a>
        </div>
        <div className="footer__link">
          <Link to={`/de/inhalte/datenschutzerklaerung`}>
            Datenschutzerklärung
          </Link>
      </div>
      </>
      ) : rootURL === 'es' ? (
        <>
          <div className="footer__link">
            <Link to="/de/inhalte/impressum">AVISO LEGAL</Link>
          </div>
          <div className="footer__link">
            <a href="https://archive.icor.info/es" target='blank'>
              ARCHIVO
            </a>
          </div>
          <div className="footer__link">
            <Link to={`/en/content/privacy-policy`}>
                POLÍTICA DE PRIVACIDAD
            </Link>
          </div>
        </>
      ) : rootURL === 'fr' ? (
        <>
          <div className="footer__link">
            <Link to="/de/inhalte/impressum">Mentions légales</Link>
          </div>
          <div className="footer__link">
            <a href="https://archive.icor.info/fr" target='blank'>
              Archives
            </a>
          </div>
          <div className="footer__link">
            <Link to={`/en/content/privacy-policy`}>
                Déclaration de confidentialité
            </Link>
          </div>
        </>
      ) : (
      <>
        <div className="footer__link">
          <Link to="/de/inhalte/impressum">Legal notice</Link>
        </div>
        <div className="footer__link">
          <a href="https://archive.icor.info/" target='blank'>
            Archive
          </a>
        </div>
        <div className="footer__link">
          <Link to={`/en/contents/privacy-policy`}>
            Privacy Policy
          </Link>
        </div>
      </>
      )}
    </footer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
