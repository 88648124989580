/**
 * NewsItemView-Ansichtskomponente.
 * @module components/theme/View/NewsItemView
 */

import React, { useEffect, useRef } from 'react'; // useRef importieren
import PropTypes from 'prop-types';
import { Container as SemanticContainer, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import cx from 'classnames';
import { find, map } from 'lodash';
import {
  hasBlocksData,
  Helmet,
  langmap,
  flattenToAppURL,
  toReactIntlLang,
} from '@plone/volto/helpers';
import { compose } from 'redux';
import { FormattedDate } from '@plone/volto/components';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import config from '@plone/volto/registry';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

// LanguageSelector Code
const LanguageSelector = ({ intl, onClickAction }) => {
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );

  const { settings } = config;

  return settings.isMultilingual && translations && translations.length ? (
    <div className="translations">
      <span>{intl.formatMessage(messages.existingTranslations)}:</span>
      <ul>
        {map(settings.supportedLanguages, (lang) => {
          const translation = find(translations, { language: lang });
  
          if (translation) {
            return (
              <li key={`language-selector-${lang}`}>
                <Link
                  aria-label={`${intl.formatMessage(
                    messages.switchLanguageTo,
                  )} ${langmap[lang].nativeName.toLowerCase()}`}
                  className={cx({ selected: toReactIntlLang(lang) === currentLang })}
                  to={flattenToAppURL(translation['@id'])}
                  title={langmap[lang].nativeName}
                  onClick={() => {
                    onClickAction();
                  }}
                >
                  {langmap[lang].nativeName}
                </Link>
              </li>
            );
          }
  
          // Wenn keine Übersetzungs-URL vorhanden ist, wird nichts zurückgegeben
          return null;
        })}
      </ul>
    </div>
  ) : null;
};

LanguageSelector.propTypes = {
  intl: PropTypes.object.isRequired,
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: () => {},
};

// End of LanguageSelector Code

/**
 * NewsItemView-Ansichtskomponente.
 * @function NewsItemView
 * @param {object} content Inhalt des Objekts.
 * @returns {string} Markup der Komponente.
 */
const messages = defineMessages({
  Content: {
    id: 'Content',
    defaultMessage: 'Inhalt',
  },
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
  existingTranslations: {
    id: 'Existing translations',
    defaultMessage: 'Existing translations',
  },
});

const slugify = (text) =>
  text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-|-$/g, '');

const NewsItemView = ({ content, intl }) => {
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;

  const headerRightRef = useRef(null); // Ref für headerRight erstellen
  const headerLeftRef = useRef(null); // Ref für headerLeft erstellen

  useEffect(() => {
    const headers = document.querySelectorAll('.article-body h2');
    const index = document.querySelector('.article-index ul');
    const headerLeft = headerLeftRef.current;
    const headerRight = headerRightRef.current;
  
    if (headers.length > 0) {
      document.querySelector('.article-index').style.display = 'block';
  
      Array.from(headers).forEach((header) => {
        let id = slugify(header.textContent);
        header.id = id;
  
        let listItem = document.createElement('li');
        listItem.innerHTML = `<a href="#${id}">${header.textContent}</a>`;
        index.appendChild(listItem);
      });
    } else {
      document.querySelector('.article-index').style.display = 'none';
    }
  
    // Scroll-Ereignislistener hinzufügen
    const hasScrolledPastTop = () => {
      if (headerRight && headerLeft) {
        return headerRight.getBoundingClientRect().bottom <= 0;
      }
      return false;
    };
  
    const scrollHandler = () => {
      if (hasScrolledPastTop() && headerLeft && headerRight) {
        headerLeft.classList.add('show');
      } else if (headerLeft && headerRight) {
        headerLeft.classList.remove('show');
      }
  
      // Fortschrittsleiste aktualisieren
      const docHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      const scrollPercentage = (scrollPosition / docHeight) * 100;
  
      const progressBar = document.getElementById("progress-bar");
  
      if (progressBar) {
        progressBar.style.width = scrollPercentage + "%";
      }
    };
  
    window.addEventListener('scroll', scrollHandler);
  
    // Bereinigung: Scroll-Ereignislistener entfernen, wenn die Komponente entladen wird
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);
  

  return hasBlocksData(content) ? (
    <div>
    <article className="article-content view-wrapper newsitem-view">
      <div className="article-header left" ref={headerLeftRef}>
          {content.preline && (
            <span className="card__article-subject">{content.preline}</span>
          )}
          <span className="headline-md h1">
            {content.title}
            {content.subtitle && ` - ${content.subtitle}`}
          </span>
          <span className="card_author_and_date">
            {content.authororganisation && (
              <span className="card__author">{content.authororganisation},</span>
            )}
            {content.review_state === 'published' && content.effective && (
              <span className="card__date">
                &nbsp;<FormattedDate date={content.document_date ? content.document_date : content.effective} />
              </span>
            )}
          </span>
          <LanguageSelector intl={intl} />
  
      </div>

      <div className="article-body">
        <div className="article-header right" ref={headerRightRef}>
          {content.preline && (
            <span className="card__article-subject">{content.preline}</span>
          )}       
          <h1 className="headline-md">
            {content.title}
            {content.subtitle && ` - ${content.subtitle}`}
          </h1>
          <div className="card_author_and_date">
            {content.authororganisation && (
              <span className="card__author">{content.authororganisation},</span>
            )}
            {content.review_state === 'published' && content.effective && (
              <span className="card__date">
                &nbsp;<FormattedDate date={content.document_date ? content.document_date : content.effective} />
              </span>
            )}
          </div>
        </div>

        {content.description && (
          <p className="documentDescription">{content.description}</p>
        )}
        {content.image && (
          <div className="article-image">
          <Image
            alt={content.title}
            title={content.title}
            src={
              content.image['content-type'] === 'image/svg+xml'
                ? flattenToAppURL(content.image.download)
                : flattenToAppURL(content.image.scales.large.download)
            }
            floated="right"
          />
          <figcaption>{ content.image_caption }</figcaption>
          </div>
        )}
        <RenderBlocks content={content} />
        {content.footer && (
          <div className="article-signatures">
            <p>{content.footer}</p>
          </div>
        )}
      
      </div>
      <div className="article-index">
        <span>{intl.formatMessage(messages.Content)}</span>
        <ul></ul>
      </div>
    </article>
      <div id="progress-bar"></div>
    </div>
  ) : (
    <Container className="view-wrapper">
        <div id="progress-bar"></div>
    </Container>
  );
};

/**
 * Eigenschaftstypen.
 * @property {Object} propTypes Eigenschaftstypen.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
  intl: PropTypes.object.isRequired,
};

export default compose(injectIntl)(NewsItemView);
