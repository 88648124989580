/**
 * Search widget component.
 * @module components/theme/SearchWidget/SearchWidget
 */

import React, { Component , useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input } from 'semantic-ui-react';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';


import searchicon from "../../../../../public/assets/search-icon.svg";
import searchclose from "../../../../../public/assets/search-close.svg";

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
});

/**
 * SearchWidget component class.
 * @class SearchWidget
 * @extends Component
 */
class SearchWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onChangeText = this.onChangeText.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      text: '',
    };
  }

  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText(event, { value }) {
    this.setState({
      text: value,
    });
  }

  /**
   * Submit handler
   * @method onSubmit
   * @param {event} event Event object.
   * @returns {undefined}
   */
  onSubmit(event) {
    const path =
      this.props.pathname?.length > 0
        ? `&path=${encodeURIComponent(this.props.pathname)}`
        : '';
    this.props.history.push(
      `/search?SearchableText=${encodeURIComponent(this.state.text)}${path}`,
    );
    // reset input value
    this.setState({
      text: '',
    });
    event.preventDefault();
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */

  toggleSearch = () => {
    this.setState((prevState) => ({
      showSearch: !prevState.showSearch,
    }));
  };

  handleKeyPress = (event) => {
    if (event.key === "/" || event.key === "Escape") {
      this.toggleSearch();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  render() {
    return (
      <Form
        action="/search"
        onSubmit={this.onSubmit}
        className={`search ${this.state.showSearch ? "show-search" : ""}`}
        id="search-bar"
      >
        <Input  
          aria-label={this.props.intl.formatMessage(messages.search)}
          onChange={this.onChangeText}
          name="SearchableText"
          value={this.state.text}
          transparent
          autoComplete="off"
          placeholder={this.props.intl.formatMessage(messages.searchSite)}
          title={this.props.intl.formatMessage(messages.search)}
        />
        <div
          className="search__button"
          id="search-button"
          onClick={this.toggleSearch}
        >
          <img
            className={`search__icon ${this.state.showSearch ? "hidden" : ""}`}
            src={searchicon}
            alt="search icon"
          />
          <img
            className={`search__close ${
              this.state.showSearch ? "" : "hidden"
            }`}
            src={searchclose}
            alt="search close"
          />
        </div>
      </Form>
    );
  }
}

export default compose(withRouter, injectIntl)(SearchWidget);

